.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: url("https://lh3.googleusercontent.com/l_3nPyy2AFOthIWLextEtX2HBIm5QgFKyQcx9tGK2-dBpTmMfrtUaqKqjqaaGa5_kMHdHekRFYoNqbAjoXU_hZjIPyKvQFqVvq-sBtosEV_S8H1eVUozQuWDaNBiPYEZmb_DGTkOsZs=w2400");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  background: #ffffffaa;
  padding: 40px;
  max-width: 600px;
  border-radius: 6px;
  box-shadow: 0px 2px 4px #323232;
}

.heading {
  text-align: center;
  margin-bottom: 10px;
}

.message {
  font-size: 1.25rem;
}
