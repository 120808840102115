@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap");

* {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  letter-spacing: 0.6px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Open Sans", sans-serif;
  // margin-bottom: 20px;
}

h1 {
  font-size: 2rem;
}
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.6rem;
}
h4 {
  font-size: 1.4rem;
}
h5 {
  font-size: 1.2rem;
}
h6 {
  font-size: 1.1rem;
}
